import { createApp } from 'vue'
import App from './App.vue'
import 'element-plus/dist/index.css'
import ElementPlus, { ID_INJECTION_KEY } from 'element-plus'
import VueClipboard from 'vue3-clipboard'
const app = createApp(App)
createApp(App).use(ElementPlus)
  .use(VueClipboard, {
    autoSetContainer: true,
    appendToBody: true
  })
  .provide(ID_INJECTION_KEY, {
    prefix: 1024,
    current: 0
  }).mount('#app')
