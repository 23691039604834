
import { getCurrentInstance, defineComponent, ref, reactive } from 'vue'
import { copyText } from 'vue3-clipboard'
import { ElMessage, linkEmits } from 'element-plus'
import {
  Document,
  Menu as IconMenu,
  Location,
  Setting
} from '@element-plus/icons-vue'

const input = ref('')
const asciiInput = ref('')
const tabPosition = ref('left')
const handleOpen = (key: string, keyPath: string[]) => {
  console.log(key, keyPath)
}
const handleClose = (key: string, keyPath: string[]) => {
  console.log(key, keyPath)
}
export default defineComponent({
  setup () {
    const datab:any = reactive({
      input: '',
      tabPosition: 'left',
      textarea: '',
      textareaIntegerToAscii: ''
    })
    // const datab:any = getCurrentInstance()

    const onChangeIntegerToAscii = async (value:string) => {
      const arr: string[] = []
      value.split(',').forEach(item => arr.push(String.fromCharCode(parseInt(item))))
      datab.textareaIntegerToAscii = arr.join(',')
    }
    const onChange = async (value: string) => {
      const fullUrl = 'https://' + value
      const url :string = 'https://s.prime541.com//url/add/?url=' + encodeURIComponent(fullUrl)
      const response:any = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify('') // body data type must match "Content-Type" header
      }).then((res) => res.text()).then(res => {
        const data = JSON.parse(res)
        datab.textarea = data.short_url
        // console.log(response)
      })
    }
    const handleCopy = () => {
      if (datab.textarea === '') {
        ElMessage.warning('请输入文本再复制')
        return
      }
      copyText(datab?.textarea, undefined, (error:any) => {
        if (error) {
          ElMessage.warning(`复制失败: ${error} ！`)
        } else {
          ElMessage.success(`复制: ${datab?.textarea} 成功！`)
        }
      })
    }

    return {
      onChange,
      handleClose,
      handleOpen,
      onChangeIntegerToAscii,
      datab,
      asciiInput
    }
  },
  name: 'App',
  components: {

    // HelloWorld
  },
  data () {
    return {
      input: '',
      tabPosition: 'left',
      textarea: '',
      textareaIntegerToAscii: ''
    }
  }
})
