import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createBlock(_component_el_tabs, {
    "tab-position": _ctx.tabPosition,
    style: {"height":"200px"},
    class: "demo-tabs"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_tab_pane, { label: "短链生成" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, { span: 12 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                onChange: _ctx.onChange,
                modelValue: _ctx.input,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.input) = $event)),
                placeholder: "需要转换的长链"
              }, {
                prepend: _withCtx(() => [
                  _createTextVNode("https://")
                ]),
                _: 1
              }, 8, ["onChange", "modelValue"]),
              _createVNode(_component_el_input, {
                modelValue: _ctx.datab.textarea,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.datab.textarea) = $event)),
                type: "textarea",
                placeholder: "生成的短链"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_el_tab_pane, { label: "IntegerToAscii" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, { span: 12 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                onChange: _ctx.onChangeIntegerToAscii,
                modelValue: _ctx.asciiInput,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.asciiInput) = $event)),
                placeholder: "IntegerToAscii , example : 97, 114, 101"
              }, {
                prepend: _withCtx(() => [
                  _createTextVNode("IntegerToAscii")
                ]),
                _: 1
              }, 8, ["onChange", "modelValue"]),
              _createVNode(_component_el_input, {
                modelValue: _ctx.datab.textareaIntegerToAscii,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.datab.textareaIntegerToAscii) = $event)),
                type: "textarea",
                placeholder: "转换后的值"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["tab-position"]))
}